@import "./_theme_colors";
@import "./_theme_font";
@import "./_theme_custom";
@import "./_dates";
//
@import "./header";

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
//@import "node_modules/add-to-calendar-button/assets/css/atcb.css";

.backgroundJogga {
  background: #045DE9!important;
}
.backgroundHoverJogga:hover{
  box-shadow: rgb(7 93 233) 3px 4px 15px;
}

.imageLocationCard {
  border-radius: 10px;
}
.border-1 {
  border-width: 1px;
}
.titleCardReservation {
  margin-top: 10px;
  color: #000;
  text-align: center;
  font-weight: bold;
  font-size: 25px;
}
.expirationBookings {
  margin-top: 10px;
  color: #000;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
.pageReservation {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 60px;
  //height: 100vh
}
.cardFlexContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 10px;
  margin-top: 0px;
}
.flexCenterImage {
  display: flex;
  justify-content: center;
}
.waiting {
  background-color: darkorange;
  padding: 10px;
}
.colorWhite {
  color: #fff;
}
.colorBlack {
  color: #000;
}
.approve {
  background-color: #20ad41;
  padding: 10px;
}
.rejected {
  background-color: #f0380b;
  padding: 10px;
}
.flex1 {
  flex: 1;
  width: 100%;
}
.textCenter {
  text-align: center;
}

.margin-xs {
  margin: 5px;
}
.bold {
  font-weight: bold;
}
.margin-vertical-m {
  margin-top: 16px;
  margin-bottom: 16px;
}
.cardReservation {
  -webkit-box-shadow: 0px 13px 21px -5px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 13px 21px -5px rgba(0,0,0,0.3);
  box-shadow: 0px 13px 21px -5px rgba(0,0,0,0.3);
  width: 80%;
  height: 80%;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
}

@media (max-width: 990px) {
  .cardReservation {
    flex-direction: column;
  }
}

@media (prefers-color-scheme: light) {
  html {
    color-scheme: light;
  }
  body {
    color: black;
    background: linear-gradient(to right, #70a7f6, #045DE9);
  }
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.containerGlass {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.cardGlass {
  text-align: center;
  background: rgba( 255, 255, 255, 0.15 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 18px );
  -webkit-backdrop-filter: blur( 18px );
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  border-radius: 1rem;
  padding: 1.5rem;
  z-index: 10;
  color: whitesmoke;
}

.titleGlass {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 1rem;
}

.subtitleGlass {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.btnGlass {
  background: none;
  border: none;
  text-align: center;
  font-size: 1rem;
  color: whitesmoke;
  background-color: #fa709a;
  padding: 0.8rem 1.8rem;
  border-radius: 2rem;
  cursor: pointer;
}

.blob {
  position: absolute;
  width: 500px;
  height: 500px;
  background: linear-gradient(
                  180deg,
                  rgba(47, 184, 255,0.42) 31.77%,
                  #5c9df1 100%
  );
  mix-blend-mode: color-dodge;
  -webkit-animation: move 25s infinite alternate;
  animation: move 25s infinite alternate;
  transition: 1s cubic-bezier(0.07, 0.8, 0.16, 1);
}

.blob:hover {
  width: 520px;
  height: 520px;
  -webkit-filter: blur(30px);
  filter: blur(30px);
  box-shadow:
          inset 0 0 0 5px rgba(255,255,255, 0.6),
          inset 100px 100px 0 0px #fa709a,
          inset 200px 200px 0 0px #784ba8,
          inset 300px 300px 0 0px #2b86c5;
}

@-webkit-keyframes move {
  from {
    transform: translate(-100px, -50px) rotate(-90deg);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
    transform: translate(500px, 100px) rotate(-10deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}

@keyframes move {
  from {
    transform: translate(-100px, -50px) rotate(-90deg);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
    transform: translate(500px, 100px) rotate(-10deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}
